import React from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const StyledButton = styled.button`
  padding: 11px 25px;
  color: #333;
  font-size: 15px;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Segoe UI, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 20px;
  border-radius: 50px;
  background-color: rgb(246, 247, 248);
  min-width: 177px;
  border: 2px solid #333;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  outline: none;
  :not(:first-child) {
    margin-left: 24px;
  }
  :hover {
    background-color: #333;
    cursor: pointer;
    color: rgb(246, 247, 248);
  }
  :active {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    margin: 15px 1rem !important;
    display: block;
  }
`

const Rotate = styled.span`
  margin-left: 10px;
  display: inline-block;
  transform: rotate(45deg) scale(1.3) translateY(1px);
  font-weight: 500;
  margin-right: -4px;
  transition: transform 0.2s ease-out;
  ${StyledButton}:hover & {
    transform: rotate(45deg) scale(1.3) translate(1px, -1px);
  }
`

const Button = ({ children, link, onPressed, showArrow }) => (
  <CopyToClipboard
    text={'oscareriknilsson@gmail.com'}
    onCopy={() => onPressed()}
  >
    <StyledButton href={link}>
      {children}
      {showArrow ? <Rotate>↑</Rotate> : null}
    </StyledButton>
  </CopyToClipboard>
)

export default Button
