import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgb(246, 247, 248);
  overflow: hidden;
`

const Space = styled.div`
  width: 100%;
`

const Page = ({ children }) => (
  <StyledPage>
    <Helmet>
      <title>Oscar Nilsson, Product Designer</title>
      <meta property="og:title" content="Oscar Nilsson, Product Designer" />
      <meta
        property="og:description"
        content="Hi! I’m Oscar — a product designer working on systems and interaction between people and software."
      />
      <meta
        name="description"
        content="Hi! I’m Oscar — a product designer working on systems and interaction between people and software."
      />
      <meta
        name="keywords"
        content="design, product design, ux, photography, blog"
      />
      <meta
        property="og:image"
        content="https://www.oscarnilsson.com/images/graphimage.jpg"
      />
      <meta property="og:url" content="https://www.oscarnilsson.com" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@oscrse" />
      <meta name="twitter:creator" content="@oscrse" />
    </Helmet>
    <Space>{children}</Space>
  </StyledPage>
)

export default Page
