import React from 'react'
import styled from 'styled-components'
import ScrollUp from '../components/scrollUp'

const FooterContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 17rem 5rem 3rem;
  width: 100%;
  background: transparent;
  @media (max-width: 1650px) {
    padding: 2rem 2rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    height: 460px;
  }
`

const EndLinks = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  height: 100%;
  /* border-top: 2px solid #ccc; */
`

const EndLink = styled.div`
  justify-content: center;
  width: 100%;
`

const EndLinkLabel = styled.span`
  text-align: left;
  width: 100px;
  display: inline-block;
  color: #4f4f4f;
  opacity: 0.5;
  margin: 0 0.5rem 0 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
`

const EndLinkLink = styled.a`
  text-align: left;
  width: 50%;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  color: #4f4f4f;
  opacity: 0.5;
  cursor: pointer;
  :visited {
    color: #4f4f4f;
  }
`

const IntroParagraph = styled.p`
  max-width: 350px;
  color: #4f4f4f;
  opacity: 0.5;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0 1rem;
  @media (max-width: 900px) {
    max-width: 250px;
  }
  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
    flex-direction: column;
    height: 400px;
    padding: 2rem 0;
  }
`

class Footer extends React.Component {
  render() {
    return (
      <FooterContainer dark={this.props.dark}>
        <EndLinks>
          <EndLink>
            <EndLinkLabel>Email</EndLinkLabel>
            <EndLinkLink mailto="oscareriknilsson@gmail.com">
              oscareriknilsson@gmail.com
            </EndLinkLink>
          </EndLink>
          <EndLink>
            <EndLinkLabel>Twitter</EndLinkLabel>
            <EndLinkLink href="https://www.twitter.com/oscrse">
              @oscrse
            </EndLinkLink>
          </EndLink>
          <EndLink>
            <EndLinkLabel>Instagram</EndLinkLabel>
            <EndLinkLink href="https://www.instagram.com/oscrse">
              @oscrse
            </EndLinkLink>
          </EndLink>
          <EndLink>
            <EndLinkLabel>Pinterest</EndLinkLabel>
            <EndLinkLink href="https://www.pinterest.com/oscrse">
              /oscrse
            </EndLinkLink>
          </EndLink>
          <EndLink>
            <EndLinkLabel>Dribbble</EndLinkLabel>
            <EndLinkLink href="https://www.dribbble.com/oscrse">
              /oscrse
            </EndLinkLink>
          </EndLink>
        </EndLinks>
        <IntroParagraph>
          Oscar Nilsson is a product designer currently working with Minecraft
          in Stockholm. When not working he's also into photography, forests and
          hanging out with his poodle.
        </IntroParagraph>
        <ScrollUp />
      </FooterContainer>
    )
  }
}

export default Footer
