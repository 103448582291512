import React from 'react'
import styled from 'styled-components'

const Container = styled.div``

const Button = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue, Helvetica,
    Arial, sans-serif;
  font-size: 30px;
  line-height: 50px;
  padding: 10px;
  border-radius: 50%;
  border: 2px solid #4f4f4f;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4f4f4f;
  font-weight: 300;
  &:hover {
    color: #fff;
    background-color: #4f4f4f;
    cursor: pointer;
  }
`

class ScrollUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoStatus: 'startState',
    }
  }

  handleClick() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Container onClick={this.handleClick}>
        <Button>↑</Button>
      </Container>
    )
  }
}

export default ScrollUp
